const initDropdowns = () => {
  document.querySelectorAll('.dropdown').forEach(function (dropDownWrapper) {
    initDropdown(dropDownWrapper);
  });
};

const selectDropdownItem = (e) => {
  // e.stopPropagation();
  const listItem = e.target;
  const dropDownWrapper = listItem.closest('.dropdown');
  const dropDownBtn = dropDownWrapper.querySelector('.dropdown__button');
  const dropDownList = dropDownWrapper.querySelector('.dropdown__list');
  const dropDownInput = dropDownWrapper.querySelector('.dropdown__input-hidden');
  dropDownBtn.innerText = listItem.innerText;
  dropDownInput.dataset.option = listItem.dataset.value;
  dropDownInput.setAttribute('value', listItem.dataset.value);
  dropDownList.classList.remove('dropdown__list--visible');
  dropDownBtn.classList.remove('dropdown__button--active');

  if (dropDownInput.dataset.option !== 'default') {
    dropDownBtn.style.color = "#292929";
    document.querySelector('.filter__reset').classList.remove('visually-hidden');
    document.querySelector('.filter__submit').classList.add('filter__submit--active');
  }
};

const initDropdown = (dropDownWrapper) => {
    const dropDownBtn = dropDownWrapper.querySelector('.dropdown__button');
    const dropDownList = dropDownWrapper.querySelector('.dropdown__list');
    const dropDownListItems = dropDownList.querySelectorAll('.dropdown__list-item');

    // Клик по кнопке. Открыть/Закрыть select
    dropDownBtn.addEventListener('click', function (e) {
      dropDownList.classList.toggle('dropdown__list--visible');
      this.classList.toggle('dropdown__button--active');
    });

    // Выбор элемента списка. Запомнить выбранное значение. Закрыть дропдаун
    dropDownListItems.forEach(listItem => {
      listItem.addEventListener('click', selectDropdownItem);
    });

    document.querySelector('.filter__reset').addEventListener('click', function() {
      location.reload();
    });

    // Клик снаружи дропдауна. Закрыть дропдаун
    document.addEventListener('click', function (e) {
      if (e.target !== dropDownBtn) {
        dropDownBtn.classList.remove('dropdown__button--active');
        dropDownList.classList.remove('dropdown__list--visible');
      }
    });

    // Нажатие на Tab или Escape. Закрыть дропдаун
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Tab' || e.key === 'Escape') {
        dropDownBtn.classList.remove('dropdown__button--active');
        dropDownList.classList.remove('dropdown__list--visible');
      }
    });
};

const FilterSelectLink = (id, field_id, field_link_id, default_text) => {
  // e.stopPropagation();

  const field = document.getElementById(field_id);
  if (!field) return false;
  const markDropDown = field.closest('.dropdown');
  const markDropDownUrl = markDropDown.dataset.ajax_url;
  const field_link = document.getElementById(field_link_id);
  if (!field_link) return false;
  const modelDropDown = field_link.closest('.dropdown');
  const modelDropDownBtn = modelDropDown.querySelector('.dropdown__button');
  const modelDropDownList = modelDropDown.querySelector('.dropdown__list');
  const modelDropDownInput = modelDropDown.querySelector('.dropdown__input-hidden');
  const selectModelId = document.getElementById('active').value;

  async function fetchData(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Проблема с fetch');
      const data = await response.text();
      const cars = data.split('::');
      let html = '';
      let text = default_text;
      for(let i = 0; i < cars.length; i++){
        let cArr = cars[i].split('^^');
        if (selectModelId != '' && selectModelId == cArr[0]) {
          text = cArr[1];
        }

        // if($.inArray(cArr[0]*1, spData ) != -1){
        html += '<li class="dropdown__list-item" data-value="' + cArr[0] + '">' + cArr[1] + '</li>';
        // }
      }

      modelDropDownBtn.innerHTML = text;
      modelDropDownInput.setAttribute('value', selectModelId);
      modelDropDownList.innerHTML = html;
      const modelDropDownListItems = modelDropDownList.querySelectorAll('.dropdown__list-item');

      modelDropDownListItems.forEach(listItem => {
        listItem.addEventListener('click', selectDropdownItem);
      });
    } catch (error) {
      console.error('Ошибка fetch:', error);
    }
  }

  fetchData(markDropDownUrl.replace(/\${id}/g, id));
};

const initFilterSelectLink = (field_id, field_link_id, default_text) => {
  const field = document.getElementById(field_id);
  if (!field) return false;
  const markDropDown = field.closest('.dropdown');
  const markDropDownListItems = markDropDown.querySelectorAll('.dropdown__list-item');
  const markDropDownInput = markDropDown.querySelector('.dropdown__input-hidden');

// Выбор элемента списка. Запомнить выбранное значение. Закрыть дропдаун
  markDropDownListItems.forEach(listItem => {
    listItem.addEventListener('click', function(e){
      const id = e.target.dataset.value;
      FilterSelectLink(id, field_id, field_link_id, default_text)
    });
  });

  if(markDropDownInput.value != '') {
    FilterSelectLink(markDropDownInput.value, field, field_link_id, default_text);
  }
};

export {initDropdowns, initDropdown, initFilterSelectLink};
